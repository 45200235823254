export const seo = {
  url: 'transport-kontenerow',
  title: {
    pl: 'Kontenery Morskie | Transport Kontenerów',
    en: 'International Transport | Transport in Countries | Transport Europe',
  },
  desc: {
    pl: `✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`,
    en: 'We provide international transport to all countries in Europe. We organize transport in the FTL and LTL model. Grow your business with us.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'europa',
    'transport międzynarodowy',
  ],
}

export const intro = {
  title: {
    pl: 'Usługi',
    en: 'Usługi',
  },
  desc: {
    pl: 'Kontenery dopasowane do specyfiki danej branży. Stawiamy na szybką realizację i dużą dostępność.',
    en: 'We provide international transport to all countries in Europe. We organize transport in the FTL and LTL model. Grow your business with us.',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Kontenery dla Twojego biznesu',
        en: 'International Transport - choose an experienced logistics partner',
      },
      texts: [
        {
          pl: 'Szukasz kontenerów dopasowanych do specyfiki danej gałęzi przemysłu? Nasze doświadczony zespół dostarczy w dowolne miejsce w Europie kontener dla Twojego Biznesu. Wybierając Omida Trade masz możliwość personalizacji kontenerów oraz ich składowania.',
          en: '<span><strong>International transport</strong> covers issues related to forwarding and logistic processes developed to a greater extent than domestic transport. This type is characterized by a very <strong>efficient cargo delivery network</strong> from pick-up to destination. We focus on choosing the right transport service, organizing <strong>safe route</strong> transport and <strong>punctual deliveries</strong>.</span>',
        },
      ],
    },
  ],
}

export const buttonBack = {
  text: {
    pl: 'Powrót do transportu',
    en: 'Back to transport',
  },
  link: '/transport/',
}
